const navBar = document.querySelector('header');

window.addEventListener('scroll', () => {

  let position = window.pageYOffset;

  if ( position >= 300 && !navBar.classList.contains('fixed-top') ) {
    navBar.classList.add('fixed-top');
  } else if ( position <= 300 && navBar.classList.contains('fixed-top') ) {
    navBar.classList.remove('fixed-top');
  }

});

let scrollTo = (element) => {
  let makeOffset = element.offsetTop;

  if ( !navBar.classList.contains('fixed-top') ){
    makeOffset = (element.offsetTop - 61);
  }

  window.scroll({
    behavior: 'smooth',
    left: 0,
    top: makeOffset
  });
}

document.getElementById("ghost").addEventListener('click', () => {
  window.scroll({
    behavior: 'smooth',
    left: 0,
    top: 0
  });
});

document.getElementById("about-item").addEventListener('click', () => {
  scrollTo(document.getElementById("about"));
});

document.getElementById("skills-item").addEventListener('click', () => {
  scrollTo(document.getElementById("skills"));
  console.log('skills');
});

document.getElementById("arrow-down").addEventListener('click', () => {
  scrollTo(document.getElementById("skills"));
});

document.getElementById("contact-item").addEventListener('click', () => {
  scrollTo(document.getElementById("contact"));
});
